$color_1: #adb0bc;
$color_2: #f8aa45;
$color_3: #ffffff;
$color_4: inherit;
$color_5: #0f161b;
$color_6: #ffffff;
$color_7: #ffffff;
$color_8: #ffffff;
$color_9: #adb0bc;
$font-family_1: 'ZebMoneyRegular';
$font-family_2: 'ZebMoneyBold2';
$font-family_3: "Font Awesome 5 Free";
$font-family_4: 'ZebMoneyBold';
$background-color_1: #0f161b;
$background-color_2: #0f161b;
$background-color_3: #f8aa45;
$background-color_4: #ffffff;
$background-color_5: #ffffff;
$border-color_1: #f8aa45;
$border-color_2: #41ff71;
$border-color_3: #41c3ff;
$border-color_4: #ffffff;
$border-color_5: #f8aa45;
$border-color_6: #e941ff;
$border-color_7: #41ff71;
$border-color_8: #41aaff;
$border-color_9: #e941ff;
$border-color_10: #41c3ff;

@font-face {
    font-family: 'ZebMoneyRegular';
    src: url('../fonts/ZebMoneyRegular.woff2') format('woff2'), url('../fonts/ZebMoneyRegular.woff') format('woff'), url('../fonts/ZebMoneyRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ZebMoneyBold';
    src: url('../fonts/ZebMoneyBold.woff2') format('woff2'), url('../fonts/ZebMoneyBold.woff') format('woff'), url('../fonts/ZebMoneyBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ZebMoneyBold2';
    src: url('../fonts/ZebMoneyBold2.woff2') format('woff2'), url('../fonts/ZebMoneyBold2.woff') format('woff'), url('../fonts/ZebMoneyBold2.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@keyframes colorChange {
    0% {
        filter: hue-rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        filter: hue-rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animate {
    0% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-reverse {
    0% {
        transform: rotate(360deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes hue {

    0%,
    100% {
        -webkit-filter: hue-rotate(250deg) brightness(1.3);
        filter: hue-rotate(250deg) brightness(1.3);
    }

    50% {
        -webkit-filter: hue-rotate(300deg) brightness(1.3);
        filter: hue-rotate(300deg) brightness(1.3);
    }
}

@-webkit-keyframes hue {

    0%,
    100% {
        filter: hue-rotate(250deg) brightness(1.3);
        -webkit-filter: hue-rotate(250deg) brightness(1.3);
    }

    50% {
        filter: hue-rotate(300deg) brightness(1.3);
        -webkit-filter: hue-rotate(300deg) brightness(1.3);
    }
}

@keyframes zm_animated-text {
    0% {
        opacity: 0;
    }

    65% {
        opacity: 1;
        text-shadow: 0 0 25px #f8aa45;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes zm_animated-text2 {
    0% {
        opacity: 0;
    }

    65% {
        opacity: 1;
        text-shadow: 0 0 25px #10181f;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

/* Animation */
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
/* RoadMap */
@-webkit-keyframes mykdRotation {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes mykdRotation {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    font-family: $font-family_1;
    font-size: 16px;
    font-weight: 400;
    color: $color_1;
    line-height: 1.7;
    background-color: $background-color_1;
    word-wrap: break-word;
    padding: 0px;
    margin: 0px;

    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/bgbody.webp);
        background-position: center;
        background-size: contain;
        background-repeat: repeat;
        z-index: -2;
        filter: brightness(0.4);
    }
}

.img {
    max-width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    width: auto;
    height: auto;
}

img {
    max-width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    width: auto;
    height: auto;
}

a {
    color: $color_2;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;

    &:focus {
        text-decoration: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }

    &:hover {
        color: $color_2;
        text-decoration: none;
    }
}

button {
    color: $color_2;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    font-family: $font-family_2;

    &:hover {
        color: $color_2;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

.loaderx {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    z-index: 99;
}

.loader__layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: transparent;
    z-index: 100;
}

.loader__container {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color_3;
    z-index: 101;
}

.loader__container__counter {
    font-size: 170px;
    font-weight: 700;
    margin: 0;
}

.loader__container__loading {
    font-size: 50px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 10px;
}

.btn {
    &:focus {
        text-decoration: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }
}

.button {
    &:focus {
        text-decoration: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }
}

input {
    &:focus {
        outline: 0;
    }
}

textarea {
    outline: 0;

    &:focus {
        outline: 0;
    }
}

h1 {
    font-family: $font-family_2;
    color: $color_3;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 2.5rem;

    a {
        color: $color_4;
    }
}

h2 {
    font-family: $font-family_2;
    color: $color_3;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 2rem;

    a {
        color: $color_4;
    }
}

h3 {
    font-family: $font-family_2;
    color: $color_3;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 1.75rem;

    a {
        color: $color_4;
    }
}

h4 {
    font-family: $font-family_2;
    color: $color_3;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 1.5rem;

    a {
        color: $color_4;
    }
}

h5 {
    font-family: $font-family_2;
    color: $color_3;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 1.25rem;

    a {
        color: $color_4;
    }
}

h6 {
    font-family: $font-family_2;
    color: $color_3;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 1rem;

    a {
        color: $color_4;
    }
}

.list-wrap {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

p {
    font-family: $font-family_1;
    font-size: 16px;
    line-height: 1.75;
    font-weight: 400;
    color: $color_1;
    margin-bottom: 15px;
}

hr {
    border-bottom: 1px solid #0f161b;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: $background-color_1;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: $background-color_1;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: $background-color_3;
}

::-moz-selection {
    background: #f8aa45;
    color: $color_5;
    text-shadow: none;
    background: #f8aa45;
    color: $color_5;
    text-shadow: none;
}

::selection {
    background: #f8aa45;
    color: $color_5;
    text-shadow: none;
}

::-moz-placeholder {
    color: $color_1;
    font-size: 16px;
    opacity: 1;
}

::placeholder {
    color: $color_1;
    font-size: 16px;
    opacity: 1;
}

.fix {
    overflow: hidden;
}

.clear {
    clear: both;
}

.main--area {
    overflow-x: hidden;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1300px;
}

.row {
    --bs-gutter-x: 30px;
}

.w-fit-content {
    width: fit-content;
}

.glowingmain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: brightness(1.5);
    pointer-events: none;
}

.glowing {
    position: relative;
    min-width: 700px;
    height: 550px;
    margin: -150px;
    transform-origin: right;
    animation: colorChange 5s linear infinite;

    &:nth-child(even) {
        transform-origin: left;
    }

    span {
        position: absolute;
        top: calc(80px * var(--i));
        left: calc(80px * var(--i));
        bottom: calc(80px * var(--i));
        right: calc(80px * var(--i));

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -8px;
            width: 6px;
            height: 6px;
            background: #f00;
            border-radius: 50%;
        }

        &:nth-child(3n + 1) {
            &::before {
                background: rgba(134, 255, 0, 1);
                box-shadow: 0 0 20px rgba(134, 255, 0, 1), 0 0 40px rgba(134, 255, 0, 1),
                    0 0 60px rgba(134, 255, 0, 1), 0 0 80px rgba(134, 255, 0, 1),
                    0 0 0 8px rgba(134, 255, 0, 0.1);
            }

            animation: animate 16s alternate infinite;
        }

        &:nth-child(3n + 2) {
            &::before {
                background: rgba(255, 214, 0, 1);
                box-shadow: 0 0 20px rgba(255, 214, 0, 1), 0 0 40px rgba(255, 214, 0, 1),
                    0 0 60px rgba(255, 214, 0, 1), 0 0 80px rgba(255, 214, 0, 1),
                    0 0 0 8px rgba(255, 214, 0, 0.1);
            }

            animation: animate-reverse 15s alternate infinite;
        }

        &:nth-child(3n + 3) {
            &::before {
                background: rgba(0, 226, 255, 1);
                box-shadow: 0 0 20px rgba(0, 226, 255, 1), 0 0 40px rgba(0, 226, 255, 1),
                    0 0 60px rgba(0, 226, 255, 1), 0 0 80px rgba(0, 226, 255, 1),
                    0 0 0 8px rgba(0, 226, 255, 0.1);
            }

            animation: animate 25s alternate infinite;
        }
    }
}

.custom-container {
    max-width: 1590px;
}

.zm_border-button {
    display: inline-flex;
    font-family: $font-family_2;
    color: $color_3;
    font-weight: 800;
    text-transform: capitalize;
    padding: 10px 10px;
    font-size: 15px;
    letter-spacing: .8px;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url(../images/btnbgborder.svg);
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 167px;
    min-height: 48px;
    z-index: 1;

    &~.zm_border-button {
        margin-left: 10px;
    }

    i {
        margin-right: 10px;
    }

    &:hover {
        color: $color_2;

        &::before {
            opacity: 1;
        }
    }
}

.zm_button.d-flex {
    .zm_border-button {
        margin-bottom: 15px;
    }
}

html.show-preloader {
    body {
        display: none;
    }
}

.zm_faq {
    padding: 50px 0 50px;
}

.zm_faq-content {
    .zm_section-title {
        margin: 0 0 55px;

        &::after {
            display: none;
        }

        .title {
            margin: 0 0 24px;
        }

        p {
            margin: 0 0;
        }
    }
}

.zm_faq-wrapper {
    .accordion-item {
        background: 0 0;
        border: none;
        border-radius: 0;
        padding-bottom: 35px;
        margin-bottom: 35px;
        border-bottom: 1px solid #202428;

        &:nth-last-child(1) {
            border-bottom: none;
        }

        &:last-child {
            margin: 0 0;
        }
    }

    .accordion-header {
        margin: 0 0;
    }

    .accordion-button {
        padding: 0 0;
        background: 0 0;
        border-radius: 0 !important;
        box-shadow: none;
        color: $color_3;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        border: none !important;
        box-shadow: none;

        &::after {
            content: "+";
            background-image: none !important;
            font-size: 16px;
            color: #ffffff;
            opacity: 1;
            width: auto;
            height: auto;
            transform: scale(1.5);
        }

        .count {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $color_5;
            background: #adb0bc;
            font-weight: 800;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -o-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            margin-right: 16px;
            -webkit-transition: all .3s ease-out 0s;
            -moz-transition: all .3s ease-out 0s;
            -ms-transition: all .3s ease-out 0s;
            -o-transition: all .3s ease-out 0s;
            transition: all .3s ease-out 0s;
        }

        &:hover {
            color: $color_2;

            .count {
                background: #f8aa45;
            }
        }

        &:not(.collapsed) {
            &::after {
                content: "-";
                transform: scaleX(2.8) scaleY(1.5);
                transform-origin: center;
            }

            .count {
                background: #f8aa45;
            }
        }
    }

    .accordion-body {
        padding: 11px 0 0 45px;
    }
}

.zm_section-title {
    position: relative;

    .zm_sub-title {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: 600;
        color: $color_2;
        line-height: 1;
        margin: 0 0 7px;
        opacity: 1;
    }

    .title {
        font-size: 45px;
        font-weight: 800;
        letter-spacing: 1px;
        margin: 0 0;
    }

    &::after {
        content: "";
        display: block;
        width: 65px;
        height: 5px;
        margin: 20px auto 0;
    }
}

.zm_section-title.text-start {
    .title {
        line-height: 1.15;
    }

    &::after {
        margin: 20px 0 0;
    }
}

.zm_animate-text {
    opacity: 0;

    em {
        opacity: 0;
    }

    span {
        animation: .8s both zm_animated-text;
    }
}

.zm_animate-text.ready {
    opacity: 1;
}

.transparent-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    height: auto;
}

.zm_header-main {
    padding: 11px 0;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    .mobile-nav-toggler {
        display: none;
        float: right;
        font-size: 26px;
        flex-direction: column;
        min-width: 45px;
        gap: 10px;
        cursor: pointer;

        span {
            display: block;
            height: 3px;
            background-color: $background-color_3;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;

            &:nth-child(1) {
                width: 26px;
            }

            &:nth-child(2) {
                width: 45px;
            }

            &:nth-child(3) {
                width: 26px;
                align-self: flex-end;
            }
        }
    }
}

.zm_header-main.zm_sticky-menu {
    padding: 0 0;
}

.zm_menu-nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.zm_menu-navbar-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-grow: 1;

    ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding: 0;
        flex-direction: row;
        flex-wrap: wrap;
        // margin: 0 auto 0 122px;
        margin: 0 0 0 auto;

        li {
            display: block;
            position: relative;
            list-style: none;

            a {
                font-size: 15px;
                font-weight: 700;
                text-transform: uppercase;
                color: $color_3;
                font-family: $font-family_2;
                padding: 38px 23px;
                display: block;
                line-height: 1;
                position: relative;
                letter-spacing: .8px;
                z-index: 1;
                text-transform: capitalize;
            }

            .sub-menu {
                position: absolute;
                left: 0;
                top: 100%;
                min-width: 230px;
                border: 1px solid #0f161b;
                background: #0f161b;
                margin: 0;
                -webkit-transform: scale(1, 0);
                -moz-transform: scale(1, 0);
                -ms-transform: scale(1, 0);
                -o-transform: scale(1, 0);
                transform: scale(1, 0);
                transform-origin: 0 0;
                -webkit-transition: all .3s ease-out 0s;
                -moz-transition: all .3s ease-out 0s;
                -ms-transition: all .3s ease-out 0s;
                -o-transition: all .3s ease-out 0s;
                transition: all .3s ease-out 0s;
                -webkit-box-shadow: 0 30px 70px 0 rgba(0, 0, 0, .15);
                -moz-box-shadow: 0 30px 70px 0 rgba(0, 0, 0, .15);
                box-shadow: 0 30px 70px 0 rgba(0, 0, 0, .15);
                border-radius: 0;
                padding: 18px 0;
                display: block;
                visibility: hidden;
                opacity: 0;
                z-index: 9;

                .sub-menu {
                    right: auto;
                    left: 100%;
                    top: 0;
                }

                li {
                    margin-left: 0;
                    text-align: left;
                    display: block;

                    a {
                        padding: 9px 15px 9px 25px;
                        line-height: 1.4;
                        font-weight: 700;
                        color: $color_3;
                        text-transform: uppercase;
                        letter-spacing: .8px;
                        position: relative;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;

                        &::before {
                            content: "";
                            display: block;
                            width: 0;
                            height: 7px;
                            background-color: $background-color_3;
                            -webkit-border-radius: 0 5px 0 0;
                            -moz-border-radius: 0 5px 0 0;
                            -o-border-radius: 0 5px 0 0;
                            -ms-border-radius: 0 5px 0 0;
                            border-radius: 0 5px 0 0;
                            margin: 7px 9px 0 0;
                            -webkit-transition: width .2s linear;
                            -moz-transition: width .2s linear;
                            -ms-transition: width .2s linear;
                            -o-transition: width .2s linear;
                            transition: width .2s linear;
                        }
                    }

                    &:hover {
                        >a {
                            &::before {
                                width: 7px;
                            }
                        }
                    }
                }

                li.active {
                    >a {
                        &::before {
                            width: 7px;
                        }
                    }
                }
            }

            &:hover {
                >.sub-menu {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }
        }
    }

    >ul {
        >li {
            >a {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 42px;
                    height: 1px;
                    top: 50%;
                    -webkit-transform: translateY(-50%) rotate(0);
                    -moz-transform: translateY(-50%) rotate(0);
                    -ms-transform: translateY(-50%) rotate(0);
                    -o-transform: translateY(-50%) rotate(0);
                    transform: translateY(-50%) rotate(0);
                    background: #f8aa45;
                    margin: 0 auto;
                    opacity: 0;
                    -webkit-transition: all .3s ease-out 0s;
                    -moz-transition: all .3s ease-out 0s;
                    -ms-transition: all .3s ease-out 0s;
                    -o-transition: all .3s ease-out 0s;
                    transition: all .3s ease-out 0s;
                }
            }

            &:hover {
                >a {
                    color: $color_2;

                    &::before {
                        opacity: 1;
                        -webkit-transform: translateY(-50%) rotate(-40deg);
                        -moz-transform: translateY(-50%) rotate(-40deg);
                        -ms-transform: translateY(-50%) rotate(-40deg);
                        -o-transform: translateY(-50%) rotate(-40deg);
                        transform: translateY(-50%) rotate(-40deg);
                    }
                }
            }
        }

        >li.active {
            >a {
                color: $color_2;

                &::before {
                    opacity: 1;
                    -webkit-transform: translateY(-50%) rotate(-40deg);
                    -moz-transform: translateY(-50%) rotate(-40deg);
                    -ms-transform: translateY(-50%) rotate(-40deg);
                    -o-transform: translateY(-50%) rotate(-40deg);
                    transform: translateY(-50%) rotate(-40deg);
                }
            }
        }
    }
}

.zm_menu-main {
    li.menu-item-has-children {
        .dropdown-btn {
            display: none;
        }
    }
}

.zm_menu-action {
    >ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        margin-left: 10px;

        li {
            position: relative;
            margin-left: 25px;

            &:first-child {
                margin-left: 0;
            }

            a {
                color: $color_3;
            }
        }
    }

    .search {
        a {
            display: block;
            font-size: 20px;

            &:hover {
                color: $color_2;
            }
        }
    }

    .header-btn {
        padding-left: 25px;

        .btn {
            color: $color_5;
            font-size: 14px;
            padding: 13px 22px;
            font-weight: 800;
        }
    }
}

.logo {
    a {
        display: inline-block;
    }

    img {
        max-width: 177px;
    }
}

.zm_sticky-menu {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    transform: unset;
    z-index: 99;
    background: transparent;
    transition: all .4s ease;
    border-radius: 0;
    opacity: 1;
    backdrop-filter: blur(15px);
    border-bottom: 1px solid rgb(255 83 205 / 51%);
    box-shadow: 0 0 40px -10px #e10361, inset 0px 0px 40px -10px #e10361;
}

.zm_sticky-menu.zm_sticky-menu-show {
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    transform: unset;
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
    opacity: 1;
}

.zm_mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 0;
    transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -moz-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -webkit-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -ms-transition: all .3s cubic-bezier(.785, .135, .15, .86) e;
    -o-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -webkit-transform: translateX(101%);
    -moz-transform: translateX(101%);
    -ms-transform: translateX(101%);
    -o-transform: translateX(101%);
    transform: translateX(101%);

    .navbar-collapse {
        display: block !important;
    }

    .nav-logo {
        position: relative;
        padding: 30px 25px;
        text-align: left;

        img {
            max-width: 150px;
        }
    }

    .navigation {
        position: relative;
        display: block;
        width: 100%;
        float: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            display: block;
            border-top: 1px solid #0f161b;

            >a {
                position: relative;
                display: block;
                line-height: 1.5;
                padding: 11px 60px 11px 25px;
                font-size: 15px;
                font-weight: 600;
                color: $color_3;
                font-family: $font-family_2;
                text-transform: uppercase;
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;
                border: none;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 0;
                    -webkit-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;
                }
            }

            ul {
                li {
                    >a {
                        margin-left: 20px;
                    }

                    ul {
                        li {
                            a {
                                margin-left: 40px;
                            }

                            ul {
                                li {
                                    a {
                                        margin-left: 60px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            >ul {
                display: none;

                >li {
                    >ul {
                        display: none;
                    }
                }
            }
        }

        li.current {
            >a {
                &::before {
                    height: 100%;
                }
            }
        }

        li.menu-item-has-children {
            .dropdown-btn {
                position: absolute;
                right: 18px;
                top: 6px;
                width: 32px;
                height: 32px;
                text-align: center;
                font-size: 16px;
                line-height: 32px;
                color: $color_3;
                background: #0f161b;
                cursor: pointer;
                border-radius: 2px;
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;
                z-index: 5;

                .plus-line {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%) rotate(0);
                    -ms-transform: translate(-50%, -50%) rotate(0);
                    transform: translate(-50%, -50%) rotate(0);
                    border-radius: 10px;
                    width: 12px;
                    height: 2px;
                    background-color: $background-color_4;
                    -webkit-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%) rotate(0);
                        -ms-transform: translate(-50%, -50%) rotate(0);
                        transform: translate(-50%, -50%) rotate(0);
                        border-radius: 10px;
                        width: 2px;
                        height: 12px;
                        background-color: $background-color_4;
                        -webkit-transition: all .5s ease;
                        -o-transition: all .5s ease;
                        transition: all .5s ease;
                    }
                }
            }

            .dropdown-btn.open {
                background-color: $background-color_3;

                .plus-line {
                    background-color: $background-color_1;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                a {
                    display: block;
                }

                ul {
                    li {
                        >a {
                            font-size: 16px;
                            margin-left: 20px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        &:last-child {
            border-bottom: 1px solid #0f161b;
        }

        >li {
            >ul {
                >li {
                    &:first-child {
                        border-top: 1px solid #0f161b;
                    }
                }
            }
        }
    }

    .close-btn {
        position: absolute;
        right: 15px;
        top: 28px;
        line-height: 1.2;
        width: 28px;
        text-align: center;
        font-size: 20px;
        color: $color_2;
        cursor: pointer;
        z-index: 10;
        transition: all .5s ease;
        padding: 4px;
    }

    .zm_social-links {
        ul {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            position: relative;
            text-align: center;
            padding: 30px 20px 20px;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 12px;

            li {
                position: relative;
                display: block;

                a {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    position: relative;
                    font-size: 16px;
                    color: $color_3;
                    -webkit-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;
                    border: 1px solid #0f161b;
                    border-radius: 3px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    -ms-border-radius: 3px;
                    -o-border-radius: 3px;
                    padding: 8px;

                    &:hover {
                        border-color: $border-color_1;
                        background: #f8aa45;
                        color: $color_5;
                    }
                }
            }
        }
    }
}

.zm_mobile-menu-bg {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all .7s ease;
    -moz-transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -ms-transition: all .7s ease;
    -o-transition: all .7s ease;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, .5);
}

.zm_menu-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #0f161b;
    padding: 0 0;
    z-index: 5;
    box-shadow: -9px 0 14px 0 rgba(0, 0, 0, .06);
}

.zm_mobile-menu-outer {
    .mobile-nav-toggler {
        position: relative;
        float: right;
        font-size: 40px;
        line-height: 50px;
        cursor: pointer;
        display: none;
        color: $color_3;
        margin-right: 30px;
        top: 15px;
    }
}

.mobile-menu-visible {
    overflow: hidden;

    .zm_mobile-menu {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);

        .close-btn {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .zm_mobile-menu-bg {
        opacity: 1;
        visibility: visible;
    }
}

.zm_banner-bg {
    background-position: center;
    background-size: cover;
    padding: 158px 0 54px;
    position: static;
    z-index: 1;
}

.zm_banner-content {
    margin: 50px 0 50px 40px;
    position: relative;
    z-index: 2;

    .zm_sub-title {
        margin: 0 0 35px;
        line-height: 1.1;
        letter-spacing: 4px;
        font-size: 20px;
        color: #f8aa45;
        position: relative;
        padding: 0px;
        font-family: $font-family_2;
        display: block;
        text-transform: uppercase;
        animation: hue-shadow-animation 6s infinite linear;
    }

    .title {
        text-transform: capitalize;
        margin: 0 0 21px;
        font-size: 150px;
        font-family: $font-family_4;
        line-height: .8;
        text-shadow: -1px 5px 0 #f8aa45;
        animation: hue-shadow-animation 6s infinite linear;
    }

    @keyframes hue-shadow-animation {
        0% {
            filter: hue-rotate(0deg);
        }

        100% {
            filter: hue-rotate(360deg);
        }
    }

    p {
        margin: 0 0;
        font-size: 16px;
        text-transform: none;
        font-weight: 400;
        line-height: 1.4;
        color: $color_3;
    }
}

.zm_button {
    margin: 55px 0 0;
}

.zm_banner-img {
    img {
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding: 5px;
        top: 0px;
        transform: scale(1.1);
        width: 400px;
        transform-origin: center;
        mix-blend-mode: lighten;
    }

    img.roundBg {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
        animation: hue 3s infinite;
        -webkit-animation: hue 3s infinite;
    }
}

.zm_banner {
    &::after {
        left: auto;
        right: 0;
        -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
    }
}

.zm_statistical-item {
    padding: 0px 15px 15px;
    margin: 0 0 30px;
    text-align: center;
    height: 100%;
    background: transparent;
    border-radius: 8px;
    transition: .7s;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0px 0px 40px -20px #ffffff;
    backdrop-filter: blur(10px);
}

.zm_statistical-item-top {
    margin: 0 0 35px;
    display: block;
    text-align: center;

    h4 {
        font-size: 16px;
        padding: 15px 5px;
        background-color: rgba(0, 0, 0, 0);
        margin-left: -15px;
        margin-right: -15px;
        clip-path: polygon(0% 0%, 100% 0, 100% 55%, 50% 100%, 0 55%);
        box-shadow: inset 0px 0px 60px -20px #fff;
        min-height: 75px;
    }
}

.zm_statistical-item-avatar {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    gap: 9px;

    .image {
        max-width: 45px;
        flex: 0 0 auto;
    }

    img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -o-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
    }

    .info {
        .name {
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
            color: $color_6;
            margin: 0 0 5px;
        }

        .userName {
            display: block;
            line-height: 1;
            font-size: 12px;
            font-weight: 600;
            color: $color_1;
            font-family: $font-family_2;
            text-decoration: underline;

            &:hover {
                color: $color_2;
            }
        }
    }
}

.zm_statistical-item-wish {
    margin-left: auto;

    a {
        color: $color_7;

        &:hover {
            color: $color_2;
        }
    }
}

.zm_statistical-item-image {
    img {
        width: auto;
        height: 160px;
        margin: 0 auto;
        animation: imgzoomin-animation 2s infinite alternate;
    }

    @keyframes imgzoomin-animation {
        0% {
            transform: scale(1) rotate(-5deg);
        }

        100% {
            transform: scale(1.05) rotate(5deg);
        }
    }
}

.zm_statistical-item-bottom {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
        line-height: 1.4;
    }

    .bid-btn {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        column-gap: 10px;
        background: #f8aa45;
        color: $color_5;
        text-transform: capitalize;
        font-family: $font-family_2;
        font-weight: 600;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -o-border-radius: 6px;
        -ms-border-radius: 6px;
        border-radius: 6px;
        font-size: 18px;
        line-height: 1;
        padding: 11px 14px;
        margin-left: auto;
        margin-right: 35px;

        &:hover {
            background: #f8aa45;
        }
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparent 0, #0f161b 50%, transparent 100%);
        flex: 0 0 100%;
        margin: 14px 0 13px;
    }
}

.zm_statistical-item-price {
    .bid {
        display: block;
        font-family: $font-family_2;
        text-transform: capitalize;
        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        margin: 0 0 7px;
    }

    .eth {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        font-size: 18px;
        color: $color_8;
        font-weight: 600;
        margin: 0 0;

        i {
            color: $color_2;
            margin-right: 10px;
        }

        span {
            color: $color_2;
            font-weight: 700;
            margin-left: 5px;
        }
    }
}

.area-background {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section-pt-95 {
    padding-top: 95px;
}

.section-pb-120 {
    padding-bottom: 120px;
}

.streamers-active {
    margin: 0 -15px 0;
}

.streamers__item {
    border: 3px solid #0f161b;
    text-align: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    padding: 3px;
    margin: 0 0 30px;
    position: relative;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;

    &:hover {
        border-color: $border-color_1;

        .streamers__thumb {
            &::before {
                opacity: 0;
            }

            &::after {
                height: 100%;
                opacity: .8;
            }
        }
    }
}

.streamers__thumb {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -o-border-radius: 6px;
        -ms-border-radius: 6px;
        border-radius: 6px;
        background: #0f161b;
        background: linear-gradient(0deg, #0f161b 0, rgba(255, 255, 255, 0) 100%);
        opacity: 1;
        pointer-events: none;
        -webkit-transition: all .4s ease-out 0s;
        -moz-transition: all .4s ease-out 0s;
        -ms-transition: all .4s ease-out 0s;
        -o-transition: all .4s ease-out 0s;
        transition: all .4s ease-out 0s;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -o-border-radius: 6px;
        -ms-border-radius: 6px;
        border-radius: 6px;
        background: #f8aa45;
        background: linear-gradient(0deg, #f8aa45 0, transparent 100%);
        opacity: 0;
        pointer-events: none;
        -webkit-transition: all .4s ease-out 0s;
        -moz-transition: all .4s ease-out 0s;
        -ms-transition: all .4s ease-out 0s;
        -o-transition: all .4s ease-out 0s;
        transition: all .4s ease-out 0s;
    }

    img {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -o-border-radius: 6px;
        -ms-border-radius: 6px;
        border-radius: 6px;
        border: 1px solid #0f161b;
        width: 100%;
    }
}

.streamers__content {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 28px;
    pointer-events: none;
    z-index: 1;

    .name {
        font-size: 20px;
        letter-spacing: 1px;
        margin: 0 0;
    }
}

.section-pb-85 {
    padding-bottom: 85px;
}

.section-pt-85 {
    padding-top: 85px;
}

.section-pb-150 {
    padding-bottom: 150px;
}

.section-pb-50 {
    padding-bottom: 50px;
}

.section-pt-50 {
    padding-top: 50px;
}

.services__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 50px;
}

.zm_ecosystem-content {
    .title {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 1.2px;
        margin: 0 0 18px;

        a {
            &:hover {
                color: $color_2;
            }
        }
    }

    p {
        font-size: 15px;
        font-weight: 500;
        margin: 0 0;
        line-height: 1.2;
    }
}

.zm_ecosystem-images {
    margin: 0 auto;
    position: relative;
    height: auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: static;
    display: block;

    img {
        width: 100%;
        max-width: 320px;
        mix-blend-mode: lighten;
    }
}

.zm_services-images {
    mix-blend-mode: lighten;

    img {
        max-width: 500px;
        width: 100%;
        animation: hue-rotate-animation 10s infinite linear;
    }

}

.zm_footer {
    background: #0f161b;
    padding: 20px 0;
    box-shadow: inset 0px 10px 20px -20px #ffffff;
    position: relative;
}

.zm_footer-copyright-text {
    p {
        margin: 0 0;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: $font-family_2;
        color: $color_9;

        span {
            color: $color_2;
        }
    }
}

.zm_animate-text.style2 {
    span {
        animation: .8s both zm_animated-text2;
    }
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    min-height: 100px;
    max-height: 150px;
    opacity: 0.12;
}

.content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: $background-color_5;
}

.parallax {
    >use {
        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;

        &:nth-child(1) {
            animation-delay: -2s;
            animation-duration: 7s;
        }

        &:nth-child(2) {
            animation-delay: -3s;
            animation-duration: 10s;
        }

        &:nth-child(3) {
            animation-delay: -4s;
            animation-duration: 13s;
        }

        &:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 20s;
        }
    }
}

.ps-timeline-sec {
    position: relative;

    .container {
        position: relative;

        ol {
            &:before {
                background: #bb7b27;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                left: 8px;
                top: -3.5px;
            }

            &:after {
                background: #bb7b27;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 100%;
                position: absolute;
                right: 8px;
                top: -3.5px;
            }
        }

        ol.ps-timeline {
            margin: 240px 0;
            padding: 0;
            border-top: 2px solid #bb7b27;
            list-style: none;

            li {
                float: left;
                width: 25%;
                padding-top: 30px;
                position: relative;

                span {
                    width: 70px;
                    height: 70px;
                    margin-left: -25px;
                    background: #010104;
                    border: 2px solid #bb7b27;
                    border-radius: 50%;
                    box-shadow: 0 0 0 0px #0f161b;
                    text-align: center;
                    line-height: 1.1;
                    color: $color_3;
                    font-size: 26px;
                    font-style: normal;
                    position: absolute;
                    top: -40px;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                span.ps-sp-top {
                    &:before {
                        content: "";
                        color: $color_2;
                        width: 2px;
                        height: 50px;
                        background: #bb7b27;
                        position: absolute;
                        top: -50px;
                        left: 50%;
                    }

                    &:after {
                        content: "";
                        color: $color_2;
                        width: 8px;
                        height: 8px;
                        background: #bb7b27;
                        position: absolute;
                        bottom: 108px;
                        left: 46%;
                        border-radius: 100%;
                    }
                }

                span.ps-sp-bot {
                    &:before {
                        content: "";
                        color: $color_2;
                        width: 2px;
                        height: 50px;
                        background: #bb7b27;
                        position: absolute;
                        bottom: -50px;
                        left: 50%;
                    }

                    &:after {
                        content: "";
                        color: $color_2;
                        width: 8px;
                        height: 8px;
                        background: #bb7b27;
                        position: absolute;
                        top: 108px;
                        left: 46%;
                        border-radius: 100%;
                    }
                }

                .img-handler-top {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 130px;
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    color: $color_3;

                    img {
                        display: table;
                        margin: 0 auto;
                    }
                }

                .img-handler-bot {
                    position: absolute;
                    margin-top: 60px;
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    color: $color_3;

                    img {
                        display: table;
                        margin: 0 auto;
                    }
                }

                b {
                    line-height: 1.2;
                    text-align: left;
                    margin-bottom: 10px;
                    background-color: transparent;
                    display: block;
                    padding: 8px 10px;
                    font-weight: 400;
                    color: #ffffff;
                    font-size: 16px;
                    border: 1px dashed #bb7b27;
                    border-radius: 4px;
                }

                p {
                    width: 100%;
                    margin: 0 auto 5px;
                    line-height: 1.2;
                    text-align: left;
                }

                .ps-top {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 100px;
                }

                .ps-bot {
                    position: absolute;
                    margin-top: 35px;
                }
            }
        }
    }
}

.zm_polygonmain {
    .zm_section-title {
        &::after {
            display: none;
        }

        img {
            max-width: 180px;
            margin: 20px auto;
        }
    }
}

.zm_tokenomicsmain {
    .zm_tokenomicsmain_images {
        max-width: 100%;
        text-align: center;

        img {
            width: 100%;
            max-width: 550px;
        }
    }

    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 0px 5px 10px;
            padding: 8px 8px 8px 15px;
            box-shadow: inset 0px 0px 10px -5px #ffffff;
            border-radius: 3px;
            font-size: 18px;
            flex: 0 0 calc(50% - 10px);
            max-width: calc(50% - 10px);
            border-left: 4px solid #ffffff;

            &:nth-child(1) {
                border-color: $border-color_2;
            }

            &:nth-child(2) {
                border-color: $border-color_3;
            }

            &:nth-child(3) {
                border-color: $border-color_4;
            }

            &:nth-child(4) {
                border-color: $border-color_5;
            }

            &:nth-child(5) {
                border-color: $border-color_6;
            }

            &:nth-child(6) {
                border-color: $border-color_7;
            }

            &:nth-child(7) {
                border-color: $border-color_8;
            }

            &:nth-child(8) {
                border-color: $border-color_9;
            }

            &:nth-child(9) {
                border-color: $border-color_1;
            }

            &:nth-child(10) {
                border-color: $border-color_10;
            }
        }
    }
}

.zm_statistical {
    .zm_statistical-item {
        h6 {
            font-size: 26px;
        }

        p {
            font-size: 20px;
        }

        margin: 0 auto 0px;
        padding: 20px 10px;
    }
}

.zm_footer-social {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    justify-content: flex-end;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        height: 25px;
        width: auto;

        &.instagram {
            height: 27px;
        }
    }

    a.backtotop {
        position: absolute;
        top: -55px;
        right: 40px;
        transform: scale(1.5);

        img {
            border-radius: 100px;
            border: 1px solid #ffffff;
        }
    }
}

.animcircle {
    width: 137px;
    height: 137px;
    position: relative;
    -webkit-animation: mykdRotation 12s linear infinite reverse;
    animation: mykdRotation 12s linear infinite reverse;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    margin: 20px 50px 0 auto;

    img {
        pointer-events: none;
    }

    svg {
        position: absolute;
        width: 64px;
        height: 64px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-33deg);
        overflow: initial;

        path {
            fill: none;
        }

        text {
            fill: #ffffff;
            text-transform: uppercase;
            font-size: 34px;
            font-weight: 400;
            letter-spacing: 2.3px;
        }
    }
}

@keyframes hue-rotate-animation {
    0% {
        filter: hue-rotate(0deg) brightness(1.2);
    }

    100% {
        filter: hue-rotate(360deg) brightness(1.2);
    }
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

@media (max-width:1500px) {
    .container {
        max-width: 1260px;
    }

    .custom-container {
        max-width: 1260px;
    }

    .zm_menu-navbar-wrap {
        ul {
            // margin: 0 auto 0 65px;
            margin: 0 0 0 auto;
        }
    }

    .zm_banner-content {
        margin: 35px 0 50px 0px;

        .title {
            font-size: 118px;
        }
    }
}

@media (max-width:1199.98px) {
    .container {
        max-width: 960px;
    }

    .custom-container {
        max-width: 960px;
    }

    .zm_faq-content {
        .zm_section-title {
            p {
                br {
                    display: none;
                }
            }
        }
    }

    .zm_faq-wrapper {
        .accordion-item {
            padding-bottom: 25px;
            margin-bottom: 25px;
        }

        .accordion-body {
            padding: 15px 0 0 0;
        }
    }

    .zm_section-title {
        .title {
            br {
                display: none;
            }
        }
    }

    .zm_header-main.zm_sticky-menu {
        padding: 18px 0;
    }

    .zm_header-main {
        .mobile-nav-toggler {
            display: flex;
        }

        padding: 25px 0;
    }

    .zm_menu-nav {
        justify-content: space-between;
    }

    .zm_menu-action {
        margin-right: 40px;

        .header-btn {
            &::before {
                box-shadow: inset 0 3px 7px 0 #f8aa45;
                opacity: .1;
            }
        }
    }

    .zm_banner-content {
        .zm_sub-title {
            font-size: 16px;
        }

        .title {
            font-size: 94px;
        }
    }

    .zm_statistical-item {
        margin: 0 auto 30px;
    }

    .services__wrapper {
        gap: 50px 45px;
    }
}

@media (max-width:991.98px) {
    .container {
        max-width: 720px;
    }

    .custom-container {
        max-width: 720px;
    }

    .zm_faq-wrapper {
        .accordion-body {
            padding: 11px 0 0 45px;
        }
    }

    .zm_banner-bg {
        padding: 125px 0 55px;
    }

    .zm_banner-content {
        margin: 0 0 70px;
        text-align: center;

        .zm_sub-title {
            font-size: 18px;
        }

        .title {
            font-size: 120px;
        }
    }

    .zm_banner-img {
        img {
            margin: 0 auto;
            max-width: 380px;
            width: 100%;
            display: block;
            transform: scale(1);
        }
    }

    .zm_statistical-item-image {
        img {
            max-height: 170px;
        }
    }

    .zm_ecosystem-main {
        .zm_section-title {
            width: 100%;
        }
    }
}

@media (max-width:767.98px) {
    .container {
        max-width: 100%;
    }

    .custom-container {
        max-width: 100%;
    }

    .zm_faq-wrapper {
        .accordion-body {
            padding: 15px 0 0 0;
        }
    }

    .zm_section-title {
        .zm_sub-title {
            margin: 0 0 10px;
        }

        .title {
            font-size: 35px;
            line-height: 1.1;
        }
    }

    .transparent-header {
        top: 0;
    }

    .zm_header-main {
        .mobile-nav-toggler {
            margin-top: 0;
        }

        padding: 20px 0;
    }

    .zm_banner-content {
        margin: 0 0 50px;

        .zm_sub-title {
            font-size: 17px;
        }

        .title {
            font-size: 18vw;
            text-shadow: -1px 3px 0 #f8aa45;
        }
    }

    .zm_ecosystem-main {
        .zm_section-title {
            text-align: center !important;

            &::after {
                margin: 20px auto 0;
            }
        }
    }

    .services__wrapper {
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
    }

    .zm_footer-copyright-text {
        text-align: center;
    }

    .zm_footer-card {
        margin-top: 10px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px) {
    .container {
        max-width: 540px;
    }

    .custom-container {
        max-width: 540px;
    }

    .zm_header-main {
        padding: 25px 0;
    }

    .zm_banner-content {
        .title {
            font-size: 17vw;
            text-shadow: -1px 5px 0 #f8aa45;
        }
    }

    .zm_statistical-item-image {
        img {
            max-height: 249px;
        }
    }

    .services__wrapper {
        gap: 50px 40px;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width:1800px) {
    .custom-container {
        max-width: 1490px;
    }

    .zm_banner-content {
        margin: 35px 0 50px 0px;
    }
}

@media (max-width:1500.98px) {
    .zm_menu-navbar-wrap {
        ul {
            li {
                a {
                    font-size: 14px;
                    padding: 30px 14px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 30vh;
    }

    h1 {
        font-size: 24px;
    }
}

@media screen and (max-width: 767px) {
    .zm_faq {
        padding: 60px 0 60px;
    }

    .ps-timeline-sec {
        .container {
            ol {
                &:before {
                    background: #f8aa45;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    top: 130px !important;
                    left: 36px !important;
                }

                &:after {
                    background: #f8aa45;
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    position: absolute;
                    right: 8px;
                    top: -3.5px;
                }
            }

            ol.ps-timeline {
                margin: 0px 0 !important;
                border-left: 2px solid #f8aa45;
                padding-left: 0 !important;
                padding-top: 120px !important;
                border-top: 0 !important;
                margin-left: 25px !important;

                li {
                    height: 140px;
                    float: none !important;
                    width: inherit !important;

                    &:nth-child(2) {
                        .img-handler-bot {
                            img {
                                width: 70px;
                            }
                        }
                    }

                    &:last-child {
                        margin: 0;
                        bottom: 0 !important;
                        height: 120px;

                        .img-handler-bot {
                            bottom: 40px !important;
                            width: 40% !important;
                            margin-left: 25px !important;
                            margin-top: 0 !important;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    span {
                        left: 0 !important;
                        width: 40px;
                        height: 40px;
                        margin-left: -21px;
                        font-size: 16px;
                        top: -15px;
                    }

                    span.ps-sp-top {
                        &:before {
                            content: none !important;
                        }

                        &:after {
                            content: none !important;
                        }
                    }

                    span.ps-sp-bot {
                        &:before {
                            content: none !important;
                        }

                        &:after {
                            content: none !important;
                        }
                    }

                    .img-handler-top {
                        position: absolute !important;
                        bottom: 150px !important;
                        width: 30% !important;
                        float: left !important;
                        margin-left: 35px !important;
                        margin-bottom: 0 !important;
                        display: none;

                        img {
                            margin: 0 auto !important;
                            width: 80% !important;
                        }
                    }

                    .img-handler-bot {
                        position: absolute !important;
                        bottom: 115px !important;
                        width: 30% !important;
                        float: left !important;
                        margin-left: 35px !important;
                        margin-bottom: 0 !important;
                        display: none;
                    }

                    p {
                        text-align: left !important;
                        width: 100% !important;
                        margin: 0 auto !important;
                        margin-top: 0px !important;
                    }

                    .ps-top {
                        width: 85% !important;
                        float: right !important;
                        right: 0;
                        top: -60px;
                    }

                    .ps-bot {
                        width: 85% !important;
                        float: right !important;
                        right: 0;
                        top: -60px;
                        margin-top: 0px;
                    }

                    b {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .section-pb-85 {
        padding-bottom: 50px;
    }

    .section-pt-85 {
        padding-top: 50px;
    }

    .section-pb-150 {
        padding-bottom: 85px;
    }

    .section-pb-50 {
        padding-bottom: 30px;
    }

    .section-pt-50 {
        padding-top: 30px;
    }

    .zm_footer-social {
        gap: 30px 20px;
        margin-top: 20px;
        justify-content: center;
    }

    .loader__container__counter {
        font-size: 50px;
    }

    .loader__container__loading {
        font-size: 20px;
    }

    .zm_faq-wrapper {
        .accordion-button {
            font-size: 16px;
        }
    }
}

@media screen and (min-width: 992px) {
    .animcircle {
        margin: 20px -110px 0 auto;
    }
}

@media screen and (max-width: 992px) {
    .zm_ecosystem-images {
        text-align: center;
        margin-top: 50px;
    }

    .zm_section-title {
        .title {
            text-align: center;
        }
    }

    .title {
        text-align: center;

        &+p {
            text-align: center;
        }
    }

    .zm_section-title.text-start {
        &::after {
            margin: 20px auto 0;
        }
    }

    .animcircle {
        margin: 50px auto 0px;
    }

    section.services-area.zm_ecosystem-main {
        padding-bottom: 0px;

        .zm_services-images {
            padding-top: 50px;
        }
    }

    .zm_button.d-flex.flex-wrap {
        justify-content: center;
    }
}

@media screen and (max-width: 575px) {
    .zm_banner-bg {
        padding: 80px 0 20px;
    }

    .zm_section-title {
        .title {
            font-size: 26px;
        }
    }
}

@media screen and (max-width: 390px) {
    .zm_tokenomicsmain {
        h2 {
            text-align: center;
        }

        ul {
            li {
                margin: 0px 0px 10px;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .zm_border-button {
        padding: 8px 4px;
        font-size: 14px;
        min-width: 140px;
        min-height: 40px;
    }
}